<template>
    <div class="home">
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
           
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.search_data" style="width:400px" placeholder="模板名称"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
           
      </el-form> 
      
      <div class="left">
        <el-button @click="Refresh()" type="success" style="font-size: 16px;font-weight: bold;" plain><i class="el-icon-refresh"></i></el-button>
        <el-button @click="addwenjuan()" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
        <el-button @click="Ordertriggering()" type="primary" style="font-size: 16px;font-weight: bold;" plain>订单触发</el-button>
        <el-button @click="Questionnairesending()" type="primary" style="font-size: 16px;font-weight: bold;" plain>问卷发送</el-button>
      </div>
      <el-button  type="info"  style="position: absolute;right: 45px;top:60px;" @click="exportlist">导出</el-button>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
       <el-table-column
                  prop="code"
                  width='350'
                  label="编号">
                </el-table-column>
                <el-table-column
                  prop="name"
                  width='250'
                  label="模板名称">
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="状态">
                  <template  slot-scope="scope">
                    {{scope.row.status == 1 ? '发布' : ''}}    
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="问卷标题">
                </el-table-column>
                <el-table-column
                  prop="update_time"
                  label="创建时间">
                </el-table-column>
                <el-table-column
                  prop="operator_name"
                  label="操作人员">
                </el-table-column>
                <el-table-column
                  label="操作">
                <template  slot-scope="scope">
                  <el-button @click='editquestion(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
                  <el-button @click='delquestion(scope.row.id)' size="mini" type="info" plain>删除</el-button>
                </template>
                </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        
        <el-dialog
        title="问卷发送"
        :visible.sync="addQuestionnairesending"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form :inline="true" :model="binduser" class="demo-form-inline">
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="binduser.search_data" style="width:400px" placeholder="模板名称"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="getbinduser('cha')">查询</el-button>
            </el-form-item>
          </el-form> 
          <el-button @click="addbinduser()" type="primary" style="font-size: 16px;font-weight: bold;" plain>发布</el-button>
            <el-table
              v-loading="loading2"
              :data="binduserdata"
              style="width: 100%"
              :header-cell-style="{background:'#F5F7FA'}"
              @selection-change="handleSelectionChange"
              >
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column
                        prop="id"
                        width='150'
                        label="序号">
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        width='250'
                        label="模板名称">
                      </el-table-column>
                      <el-table-column
                        prop="status"
                        label="状态">
                        <template  slot-scope="scope">
                          {{scope.row.status == 1 ? '启用' : '禁用'}}    
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="s_time"
                        label="开始时间">
                        <template  slot-scope="scope">
                          {{scope.row.s_time | formatDate2}}    
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="e_time"
                        label="结束时间">
                        <template  slot-scope="scope">
                          {{scope.row.e_time | formatDate2}}    
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="operator_name"
                        label="操作人员">
                      </el-table-column>
                      <el-table-column
                        label="操作">
                      <template  slot-scope="scope">
                        <el-button @click='editbinduserdetail(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
                      </template>
                      </el-table-column>
            </el-table>
            <div class="pt20"></div>
        <el-pagination
        style="text-align: center;"
          @current-change="handleCurrentChange2"
          :current-page.sync="current_page2"
          :page-size="per_page2"
          layout="total,prev, pager, next, jumper"
          :total="total2">
        </el-pagination>
        </div>
      </el-dialog>
        <el-dialog
        title="订单触发"
        :visible.sync="addOrdertriggering"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form :inline="true" :model="getblist" class="demo-form-inline">
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="getblist.search_data" style="width:400px" placeholder="模板名称"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="getblists('cha')">查询</el-button>
            </el-form-item>
          </el-form> 
          <el-button @click="addbind()" type="primary" style="font-size: 16px;font-weight: bold;" plain>发布</el-button>
            <el-table
              v-loading="loading2"
              :data="blistdata"
              style="width: 100%"
              :header-cell-style="{background:'#F5F7FA'}"
              @selection-change="handleSelectionChange"
              >
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column
                        prop="id"
                        width='150'
                        label="序号">
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        width='250'
                        label="模板名称">
                      </el-table-column>
                      <el-table-column
                        prop="status"
                        label="状态">
                        <template  slot-scope="scope">
                          {{scope.row.status == 1 ? '启用' : '禁用'}}    
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="order_group"
                        label="订单类型">
                        <template  slot-scope="scope">
                          {{scope.row.order_group == 1 ? '测试' : ''}}
                          {{scope.row.order_group == 2 ? '重复' : ''}}
                          {{scope.row.order_group == 3 ? '其他' : ''}}
                          {{scope.row.order_group == 4 ? '取消' : ''}}
                          {{scope.row.order_group == 0 ? '常规' : ''}}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="order_state"
                        label="订单状态">
                        <template  slot-scope="scope">
                          {{scope.row.order_state == 1 ? '处理中' : ''}}
                          {{scope.row.order_state == 2 ? '待付款' : ''}}
                          {{scope.row.order_state == 3 ? '投诉完成' : ''}}
                          {{scope.row.order_state == 4 ? '索赔成功' : ''}}
                          {{scope.row.order_state == -1 ? '取消' : ''}}
                          {{scope.row.order_state == 0 ? '待审核' : ''}}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="operator_name"
                        label="操作人员">
                      </el-table-column>
                      <el-table-column
                        label="操作">
                      <template  slot-scope="scope">
                        <el-button @click='editbind(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
                      </template>
                      </el-table-column>
            </el-table>
            <div class="pt20"></div>
        <el-pagination
        style="text-align: center;"
          @current-change="handleCurrentChange2"
          :current-page.sync="current_page2"
          :page-size="per_page2"
          layout="total,prev, pager, next, jumper"
          :total="total2">
        </el-pagination>
        </div>
      </el-dialog>
      <el-dialog
        title="发布"
        :visible.sync="addbindusershow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class="putbindcalls">
          <div>
            <template>
              <div class="block">
                <span class="demonstration">开始时间：</span>
                <el-date-picker
                  v-model="binduserdetail.s_time"
                  type="datetime"
                  default-time="12:00:00"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              <div class="block">
                <span class="demonstration">结束时间：</span>
                <el-date-picker
                  v-model="binduserdetail.e_time"
                  type="datetime"
                  default-time="12:00:00"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              模板:
              <el-select v-model="binduserdetail.name" :change="setcode()" filterable placeholder="请选择">
                <el-option 
                  v-for="item in allquestdata"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>
              </el-select>
            </template>
          </div>
          <div class="foot">
            <el-button @click="put_binduser()" class="btnblue" type="primary" plain>绑定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="发布"
        :visible.sync="addbindshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class="putbindcalls">
          <div>
            <template>
              订单类型:
              <el-select v-model="binddetail.order_group" placeholder="请选择">
                <el-option
                  v-for="item in ordertypelist"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </div>
          <div>
            <template>
              订单状态:
              <el-select v-model="binddetail.order_state" placeholder="请选择">
                <el-option
                  v-for="item in orderstate"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </div>
          <div>
            <template>
              <div class="block">
                <span class="demonstration">开始时间：</span>
                <el-date-picker
                  v-model="binddetail.s_time"
                  type="date"
                  format='yyyy-MM-dd'
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              <div class="block">
                <span class="demonstration">结束时间：</span>
                <el-date-picker
                  v-model="binddetail.e_time"
                  type="date"
                  format='yyyy-MM-dd'
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              模板:
              <el-select v-model="binddetail.name" :change="setcode()" filterable placeholder="请选择">
                <el-option 
                  v-for="item in allquestdata"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>
              </el-select>
            </template>
          </div>
          <div class="foot">
            <el-button @click="put_bind()" class="btnblue" type="primary" plain>绑定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑"
        :visible.sync="editbinduserdetailshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class="putbindcalls">
          <div>
            <template>
              <el-radio v-model="binduserdetail.status" :label="1">启用</el-radio>
              <el-radio v-model="binduserdetail.status" :label="0">禁用</el-radio>
            </template>
          </div>
          <div>
            <template>
              <div class="block">
                <span class="demonstration">开始时间：</span>
                <el-date-picker
                  v-model="binduserdetail.s_time"
                  type="datetime"
                  default-time="12:00:00"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              <div class="block">
                <span class="demonstration">结束时间：</span>
                <el-date-picker
                  v-model="binduserdetail.e_time"
                  type="datetime"
                  default-time="12:00:00"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              模板:
              <el-select v-model="binduserdetail.name" :change="setcode2()" filterable placeholder="请选择">
                <el-option 
                  v-for="item in allquestdata"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>
              </el-select>
            </template>
          </div>
          <div class="foot">
            <el-button @click="post_bind_user()" class="btnblue" type="primary" plain>绑定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑"
        :visible.sync="editbindshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class="putbindcalls">
          <div>
            <template>
              <el-radio v-model="binddetail.status" :label="1">启用</el-radio>
              <el-radio v-model="binddetail.status" :label="0">禁用</el-radio>
            </template>
          </div>
          <div>
            <template>
              订单类型:
              <el-select v-model="binddetail.order_group" placeholder="请选择">
                <el-option
                  v-for="item in ordertypelist"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </div>
          <div>
            <template>
              订单状态:
              <el-select v-model="binddetail.order_state" placeholder="请选择">
                <el-option
                  v-for="item in orderstate"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </div>
          <div>
            <template>
              <div class="block">
                <span class="demonstration">开始时间：</span>
                <el-date-picker
                  v-model="binddetail.s_time"
                  type="date"
                  format='yyyy-MM-dd'
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              <div class="block">
                <span class="demonstration">结束时间：</span>
                <el-date-picker
                  v-model="binddetail.e_time"
                  type="date"
                  format='yyyy-MM-dd'
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </template>
          </div>
          <div>
            <template>
              模板:
              <el-select v-model="binddetail.name" :change="setcode()" filterable placeholder="请选择">
                <el-option 
                  v-for="item in allquestdata"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>
              </el-select>
            </template>
          </div>
          <div class="foot">
            <el-button @click="post_bind()" class="btnblue" type="primary" plain>绑定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="添加"
        :visible.sync="addquestion"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class='conentbox'>
          <div class="contflex">
            <div>
              <el-button @click="addques(1)" class="btnblack" type="info" plain>单选题</el-button>
              <el-button @click="addques(2)" class="btnblack" type="info" plain>多选题</el-button>
              <el-button @click="addques(3)" class="btnblack" type="info" plain>问答题</el-button>
            </div>
            <div class="meibi">
              <div class="list">
                <div class="listname">模板名称：</div>
                <div class="content"><el-input v-model="QuestionnaireAdditionData.name" placeholder="请输入"></el-input></div>
              </div>
              <div class="list">
                <div class="listname">问卷标题(中)：</div>
                <div class="content"><el-input v-model="QuestionnaireAdditionData.title" placeholder="请输入"></el-input></div>
              </div>
              <div class="list">
                <div class="listname">问卷标题(英)：</div>
                <div class="content"><el-input v-model="QuestionnaireAdditionData.en_title" placeholder="请输入"></el-input></div>
              </div>
            </div>
          </div>
          <div style='height:230px;'></div>
          <div :key="index" v-for="(que,index) in questiondata" class="meibi">
            <div class="list">
              <div class="listname">类型：</div>
              <div class="content">
                <el-button v-if="que.type == 1" @click="question()" class="btnblack" type="info" plain>单选框</el-button>
                <el-button v-if="que.type == 2" @click="question()" class="btnblack" type="info" plain>复选框</el-button>
                <el-button v-if="que.type == 3" @click="question()" class="btnblack" type="info" plain>填空题</el-button>
                <el-button @click="addshows(index)" :class="que.relation ? '' : 'btnblack'"  :type="que.relation ? 'primary' : 'info'" plain>题目关联</el-button>
                <el-button @click="delque(index)" class="btnblack" type="info" >删除</el-button>
              </div>
            </div>
            <div class="list">
              <div class="listname">问题名称：</div>
              <div class="content"><el-input v-model="que.name" placeholder="请输入"></el-input></div>
            </div>
            <div class="list">
              <div class="listname">问题名称（英）：</div>
              <div class="content"><el-input v-model="que.en_name" placeholder="请输入"></el-input></div>
            </div>
            <div v-if="que.type != 3">
            <div :key="itemi" v-for="(item,itemi) in que.item"  class="list">
              <div class="listname">选项：</div>
              <div class="content2">
                <el-input style="margin-right: 10px;" v-model="item.name" placeholder="中"></el-input>
                <el-input v-model="item.en_name" placeholder="英"></el-input>
                <div style="height:40px;display: flex;padding: 5px;">
                  <el-button @click="edhas(index,itemi)" :class="{'btngr':item.has_text == 1,'btnblack':item.has_text == 0}" type="info" ><i class="el-icon-s-order"></i></el-button>
                  <el-button @click="dlhas(index,itemi)" class="btnblack" type="info" plain><i class="el-icon-close"></i></el-button>
                  <el-button class="btnblack" type="info" plain><i class="el-icon-rank"></i></el-button>
                </div>
              </div>
            </div>
            <div class="list">
              <div class="listname"></div>
              <div class="content"><el-button @click="addhas(index)" class="btnblack" type="info" plain><i class="el-icon-plus"></i>追加</el-button></div>
            </div>
          </div>
          </div>
          <div class="foot">
            <el-button @click="Questionnaire_addition()" class="btnblue" type="primary" plain>确定</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title="题目关联"
        :visible.sync="adqueshows"
        :fullscreen="false"
        :modal="false"
        width="60%"
        hight="60%"
        center>
        <div class="Relatedtopics">
            <div class="list">
              <div>当前题目：</div>
              <div>{{adquedata.name}}</div>
            </div>
            <div class="list">
            <div>关联题目：</div>
            <div>
              <el-cascader v-if="addquedata.length" :options="addquedata" :props="adprops" v-model="asdasd"
               @change="selectassociation(adquedata.code,$event)" clearable></el-cascader>
            </div>
          </div>
          <div> <el-button class="gl" type="primary" @click="adassociation">关联</el-button></div>
        </div>
      </el-dialog>
      <el-dialog
        title="修改关联题目"
        :visible.sync="edqueshows"
        :fullscreen="false"
        :modal="false"
        width="60%"
        hight="60%"
        center>
        <div class="Relatedtopics">
            <div class="list">
              <div>当前题目：</div>
              <div>{{adquedata.name}}</div>
            </div>
            <div class="list">
            <div>关联题目：</div>
            <div>
              <el-cascader v-if="eddquedata.length" :options="eddquedata" :props="edprops" v-model="zxczxc" @change="selecteidtassociation(adquedata.code,$event)" clearable></el-cascader>
            </div>
          </div>
          <div> <el-button class="gl" type="primary" @click="edddation">关联</el-button></div>
        </div>
      </el-dialog>
      <el-dialog
        title="修改问卷"
        :visible.sync="editactionlistshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class="conentbox">
          <div class="contflex">
            <div>
              <el-button @click="eddques(1)" class="btnblack" type="info" plain>单选题</el-button>
              <el-button @click="eddques(2)" class="btnblack" type="info" plain>多选题</el-button>
              <el-button @click="eddques(3)" class="btnblack" type="info" plain>问答题</el-button>
            </div>
            <div class="meibi">
              <div class="list">
                <div class="listname">模板名称：</div>
                <div class="content"><el-input v-model="questionInfo.name" placeholder="请输入"></el-input></div>
              </div>
              <div class="list">
                <div class="listname">问卷标题(中)：</div>
                <div class="content"><el-input v-model="questionInfo.title" placeholder="请输入"></el-input></div>
              </div>  
              <div class="list">
                <div class="listname">问卷标题(英)：</div>
                <div class="content"><el-input v-model="questionInfo.en_title" placeholder="请输入"></el-input></div>
              </div>
            </div>
          </div>
          <div style='height:230px;'></div>
          <div :key="index" v-for="(que,index) in questionInfo.wj_question" class="meibi">
            <div class="list">
              <div class="listname">类型：</div>
              <div class="content">
                <el-button v-if="que.type == 1" @click="question()" class="btnblack" type="info" plain>单选框</el-button>
                <el-button v-if="que.type == 2" @click="question()" class="btnblack" type="info" plain>复选框</el-button>
                <el-button v-if="que.type == 3" @click="question()" class="btnblack" type="info" plain>填空题</el-button>
                
                <el-button @click="eddshows(index)" :class="que.relation ? '' : 'btnblack'"  :type="que.relation ? 'primary' : 'info'" plain>题目关联</el-button>
                <el-button @click="eeelque(index)" class="btnblack" type="info" >删除</el-button>
              </div>
            </div>
            <div class="list">
              <div class="listname">问题名称：</div>
              <div class="content"><el-input v-model="que.name" placeholder="请输入"></el-input></div>
            </div>
            <div class="list">
              <div class="listname">问题名称（英）：</div>
              <div class="content"><el-input v-model="que.en_name" placeholder="请输入"></el-input></div>
            </div>
            <div v-if="que.type != 3">
            <div :key="itemi" v-for="(item,itemi) in que.wj_item"  class="list">
              <div class="listname">选项：</div>
              <div class="content2">
                <el-input style="margin-right: 10px;" v-model="item.name" placeholder="中"></el-input>
                <el-input v-model="item.en_name" placeholder="英"></el-input>
                <div style="height:40px;display: flex;padding: 5px;">
                  <el-button @click="eedhas(index,itemi)" :class="{'btngr':item.has_text == 1,'btnblack':item.has_text == 0}" type="info" ><i class="el-icon-s-order"></i></el-button>
                  <el-button @click="edlhas(index,itemi)" class="btnblack" type="info" plain><i class="el-icon-close"></i></el-button>
                  <el-button class="btnblack" type="info" plain><i class="el-icon-rank"></i></el-button>
                </div>
              </div>
            </div>
            <div class="list">
              <div class="listname"></div>
              <div class="content"><el-button @click="eddhas(index)" class="btnblack" type="info" plain><i class="el-icon-plus"></i>追加</el-button></div>
            </div>
            </div>
          </div>
          <div class="foot">
            <el-button @click="Questionnaire_eddition()" class="btnblue" type="primary" plain>确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'
import globla from '../../globla'
import matDate from '../../lib/dateFormat';

export default {
  name: 'Home',
  data(){
    return {
      questionInfo:{},
      restaurants: [],
      adquedata:{},
      adqueshows:false,
      edqueshows:false,
      asdasd:'',
      zxczxc:'',
      ordertypelist:[],
      eddquedata:[],
      addquedata:[],
      orderstate:[{
        name:'待审核',
        value:0,
      },{
        name:'处理中',
        value:1,
      },{
        name:'待付款',
        value:2,
      },{
        name:'投诉完成',
        value:3,
      },{
        name:'索赔成功',
        value:4,
      },{
        name:'取消',
        value:-1,
      }],
      loading:true,
      loading2:true,
      total2:0,
      per_page2: 0,
      current_page2:1,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      QuestionnaireAdditionData:{
        name:'',
        title:'',
        en_title:'',
        question:[],
      },
      getblist:{
        search_data:'',
      },
      binduser:{
        search_data:'',
      },
      actionlogtypelist:[],
      actionlogtypelist2:[],
      actionlogtypelist3:[],
      actionlogtypelist4:[],
      questiondata:[],
      blistdata:[],
      binduserdata:[],
      adprops:{
        value:'code',
        label:'name',
        children:'item',
        expandTrigger:'hover'
      },
      edprops:{
        value:'code',
        label:'name',
        children:'wj_item',
        expandTrigger:'hover'
      },
      payChannel:[],//支付渠道
      formInline: {
        search_data:'',//检索内容
      },
      status:'',//支付渠道
      timevalue:'',//交易日期
      editbindshow:false,
      editbinduserdetailshow:false,
      addbindshow:false,
      addbindusershow:false,
      binddetail:{},
      binduserdetail:{},
      // 选中数组
      ghs: [],
      nums:[],
      allquestdata:[],
      logform:{
        actionlogtype1:'',
        actionlogtype2:'',
        actionlogtype3:'',
        actionlogtype4:'',
      },
     //选中的记录数量
     initdata:{
      search_data:'',//检索内容
     },
     associationrelation:'',
     associationcode:'',
     selectedNum:0,
     editactionlistshow:false,
     addquestion:false,
     addOrdertriggering:false,
     addQuestionnairesending:false,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate2(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
    selectassociation(code,value){//value[0]是问题code value[1]是选项code 新增的没有id 用code作为标识
      let _this = this;
      let fatherchail_item = [];//父亲问题的选项
      let q_item = 0;//序号
      for(let i in _this.addquedata){//根据问题code 找到子选项
        if(_this.addquedata[i].code == value[0]){
          fatherchail_item = _this.addquedata[i].item;
        }
      }
      for(let i in fatherchail_item){//通过选项code 找到下标
        if(fatherchail_item[i].code == value[1]){
          q_item = ++i;
        }
      }
      _this.associationrelation = {
            target_q:code,//自己
            r_q_code:value[0],//父亲code
            q_item:q_item,  //序号
          };
      _this.associationcode = code;
    },
    adassociation(){
      let _this = this;
      for(let i in _this.questiondata){
        if(_this.questiondata[i].code == _this.associationcode){
          _this.questiondata[i].relation = _this.associationrelation;
        }
      }
      _this.adqueshows = false;
    },
    selecteidtassociation(code,value){//value[0]是问题code value[1]是选项code
      let _this = this;
      let fatherchail_item = [];//父亲问题的选项
      let q_item = 0;//序号
      for(let i in _this.eddquedata){//根据问题code 找到子选项
        if(_this.eddquedata[i].code == value[0]){
          fatherchail_item = _this.eddquedata[i].wj_item;
        }
      }
      for(let i in fatherchail_item){//通过选项code 找到下标
        if(fatherchail_item[i].code == value[1]){
          q_item = ++i;
        }
      }
      _this.associationrelation = {
            target_q:code,//自己
            r_q_code:value[0],//父亲code
            q_item:q_item,  //序号
          };
      _this.associationcode = code;
    },
    edddation(){
      let _this = this;
      for(let i in _this.questionInfo.wj_question){
        if(_this.questionInfo.wj_question[i].code == _this.associationcode){
          _this.questionInfo.wj_question[i].relation = _this.associationrelation;
        }
      }
      _this.edqueshows = false;
    },
      Refresh(){
        let _this = this;
        _this.loading = true;
        axios.get(config.question,{params:_this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data) 
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setcode(){
        let _this = this;
        for(let i in _this.allquestdata){
          if(_this.allquestdata[i].code == _this.binddetail.name){
            _this.binddetail.name = _this.allquestdata[i].name;
            _this.binddetail.wj_model_code = _this.allquestdata[i].code;
          }
        }
      },
      setcode2(){
        let _this = this;
        for(let i in _this.allquestdata){
          if(_this.allquestdata[i].code == _this.binduserdetail.name){
            _this.binduserdetail.name = _this.allquestdata[i].name;
            _this.binduserdetail.wj_model_code = _this.allquestdata[i].code;
          }
        }
      },
      exportlist(){
        let _this = this;
          if(_this.ghs.length == 0){
            _this.$message.error('请勾选您要导出的内容!');
            return;
          }
          if(_this.ghs.length > 1){
            _this.$message.error('只能导出单个!');
            return;
          }
          location.href= `${globla.SERVE_URL+'/'+config.export}?code=${_this.ghs[0]}&api_token=${localStorage.getItem('Authorization')}`;
       
      },
      addshows(i){
        let _this = this;
        _this.adqueshows = true;
        _this.adquedata = _this.questiondata[i];
        let arr =_this.questiondata.slice(0,i);//自身之前的
        _this.addquedata = [];
        for(let i in arr){//排除填空题
          if(arr[i].type != 3){
            _this.addquedata.push(arr[i]);
          }
        }
        _this.asdasd = [];
        if(_this.questiondata[i].relation){
          let fcode = _this.questiondata[i].relation.r_q_code;//父亲code
          let findex = _this.questiondata[i].relation.q_item - 1;//父亲选项下标
          for(let i in _this.addquedata){
            if(_this.addquedata[i].code == fcode){
                _this.asdasd.push(_this.addquedata[i].code);//父亲code
                _this.asdasd.push(_this.addquedata[i].item[findex].code);//父亲选项的code
            }
          }
        }
      },
      eddshows(i){
        let _this = this;
        let fcode = _this.questionInfo.wj_question[i].relation.r_q_code;//父亲code
        let findex = _this.questionInfo.wj_question[i].relation.q_item - 1;//父亲选项下标
        _this.edqueshows = true;
        _this.adquedata = _this.questionInfo.wj_question[i];
        let arr =_this.questionInfo.wj_question.slice(0,i);//自身之前的
        _this.eddquedata = [];
        for(let i in arr){//排除填空题
          if(arr[i].type != 3){
            _this.eddquedata.push(arr[i]);
          }
        }
        _this.zxczxc = [];
        for(let i in _this.eddquedata){
          if(_this.eddquedata[i].code == fcode){
              _this.zxczxc.push(_this.eddquedata[i].code);//父亲code
              _this.zxczxc.push(_this.eddquedata[i].wj_item[findex].code);//父亲选项的code
          }
        }
      },
      eedhas(i,g){
        let _this = this;
        _this.questionInfo.wj_question[i].wj_item[g].has_text = _this.questionInfo.wj_question[i].wj_item[g].has_text ? 0 : 1;
      },
      edhas(i,g){
        let _this = this;
        _this.questiondata[i].item[g].has_text = _this.questiondata[i].item[g].has_text ? 0 : 1;
      },
      eddhas(i){
        let _this = this;
        _this.questionInfo.wj_question[i].wj_item.push({
            code:_this.questionInfo.wj_question[i].wj_item.length + 1,
            name:'',
            en_name:'',
            has_text:0,
          })
       },
      addhas(i){
        let _this = this;
        _this.questiondata[i].item.push({
            code:_this.questiondata[i].item.length + 1,
            name:'',
            en_name:'',
            has_text:0,
          })
       },
      dlhas(i,g){
        let _this = this;
        _this.questiondata[i].item.splice(g,1); 
       },
      edlhas(i,g){  
        let _this = this;
        _this.questionInfo.wj_question[i].wj_item.splice(g,1);
       },
      eeelque(i){
        let _this = this;
        _this.questionInfo.wj_question.splice(i,1)
      },
      delque(i){
        let _this = this;
        _this.questiondata.splice(i,1)
      },
      eddques(type){//1单选2多选3问答
        let _this = this;
        _this.questionInfo.wj_question.push({
          code:new Date().getTime() + '',
          name:'',
          en_name:'',
          type:type,
          wj_item:[{
            code:1,
            name:'',
            en_name:'',
            has_text:0,
          },{
            code:2,
            name:'',
            en_name:'',
            has_text:0,
          }],
          relation:'',
        })
      },
      addques(type){//1单选2多选3问答
        let _this = this;
        _this.questiondata.push({
          code:new Date().getTime() + '',
          name:'',
          en_name:'',
          type:type,
          item:[{
            code:1,
            name:'',
            en_name:'',
            has_text:0,
          },{
            code:2,
            name:'',
            en_name:'',
            has_text:0,
          }],
          relation:'',
        })
      },
      put_binduser(){
        let _this = this;
       let data = _this.binduserdetail;
       axios.put(config.bind_user,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                }); 
              _this.addbindusershow = false;
              _this.getbinduser();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      put_bind(){
        let _this = this;
       let data = _this.binddetail;
       data.e_time = data.e_time ? data.e_time : '';
       axios.put(config.bind,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                }); 
              _this.addbindshow = false;
              _this.getblists();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      post_bind_user(){
       let _this = this;
       let data = _this.binduserdetail;
       axios.post(config.bind_user,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                }); 
              _this.editbinduserdetailshow = false;
              _this.getbinduser();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      post_bind(){
        let _this = this;
       let data = _this.binddetail;
       axios.post(config.bind,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                }); 
              _this.editbindshow = false;
              _this.getblists();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      Questionnaire_eddition(){
       let _this = this;
       let question = [];
       for(let i in _this.questionInfo.wj_question){
        question.push({
          code:_this.questionInfo.wj_question[i].code,
          name:_this.questionInfo.wj_question[i].name,
          en_name:_this.questionInfo.wj_question[i].en_name,
          type:_this.questionInfo.wj_question[i].type,
          item:_this.questionInfo.wj_question[i].wj_item ? JSON.stringify(_this.questionInfo.wj_question[i].wj_item) : '',
          relation:_this.questionInfo.wj_question[i].relation ? JSON.stringify(_this.questionInfo.wj_question[i].relation) : '',
        })
       }
        let data = {
          id:_this.questionInfo.id,
          code:_this.questionInfo.code,
          name:_this.questionInfo.name,
          title:_this.questionInfo.title,
          en_title:_this.questionInfo.en_title,
          question:question,
        };
     
       axios.post(config.question,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                }); 
              _this.editactionlistshow = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },  
      Questionnaire_addition(){
       let _this = this;
       _this.QuestionnaireAdditionData.question = _this.questiondata;
       let data = JSON.parse(JSON.stringify(_this.QuestionnaireAdditionData));
       for(let i in data.question){
        data.question[i].item = JSON.stringify(data.question[i].item);
        data.question[i].relation = data.question[i].relation ? JSON.stringify(data.question[i].relation) : '';
       }
       axios.put(config.question,data)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                  message: response.data.message,
                  type: 'success'
                });
              _this.addquestion = false;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      question(){
        let _this = this;
        _this.addquestion = true;
      },
      addwenjuan(){
        let _this = this;
        _this.addquestion = true;
        _this.QuestionnaireAdditionData  = {
          name:'',
          title:'',
          en_title:'',
          question:[],
        };
        _this.questiondata = [];
      },
      Questionnairesending(){
        let _this = this;
        _this.addQuestionnairesending = true;
        _this.binduser.search_data = '';
        _this.getbinduser();
      },
      Ordertriggering(){
        let _this = this;
        _this.addOrdertriggering = true;
        _this.getblist.search_data = '';
        _this.getblists();
      },
      getbinduser(cha){
        let _this = this;
        if(cha=='cha'){
          _this.binduser.page = 1;
        }
        _this.addQuestionnairesending = true;
        _this.loading2 = true;
        axios.get(config.bind_user,{params:_this.binduser})
        .then(response => {
          if(response.data.code == 200){
            _this.binduserdata = response.data.data.data;
            _this.setpage2(response.data.data)
          }else{
              _this.$message.error(response.data.message);
            }
        _this.loading2 = false;
        })
        .catch(error => {
          _this.$message.error(error);
        })
      },
      getblists(cha){
        let _this = this;
        if(cha=='cha'){
          _this.getblist.page = 1;
        }
        _this.addOrdertriggering = true;
        _this.loading2 = true;
        axios.get(config.blist,{params:_this.getblist})
        .then(response => {
          if(response.data.code == 200){
            _this.blistdata = response.data.data.data;
            _this.setpage2(response.data.data)
          }else{
              _this.$message.error(response.data.message);
            }
        _this.loading2 = false;
        })
        .catch(error => {
          _this.$message.error(error);
        })
      },
      editsubmitactionlog() {
        let _this = this;
        if (!this.logform.actionlogtype1 || !this.logform.remark) {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        this.logform.order_no = _this.topOrder_no;
        let data = {
          id:_this.logform.id,
          action:_this.logform.actionlogtype1 ? _this.logform.actionlogtype1 : 0,
          specific_situation:_this.logform.actionlogtype2 ? _this.logform.actionlogtype2 : 0,
          specific_doubts:_this.logform.actionlogtype3 ? _this.logform.actionlogtype3 : 0,
          specific_materials:_this.logform.actionlogtype4 ? _this.logform.actionlogtype4.toString() : '',
          remark:_this.logform.remark ? _this.logform.remark : '',
        };
        this.$refs.logform.validate(valid => {
          if (valid) {
            axios.post(config.action_log,data)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.handleSelect('10');
                  _this.editactionlistshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
    editaclog(){
      let _this = this;
      _this.editactionlistshow = true;
     
    },
    editquestion(id){
      let _this = this;
        axios.get(config.question_detail,{params:{id:id}})
        .then(response => {
          if(response.data.code == 200){
            _this.editactionlistshow = true;
            _this.questionInfo = response.data.data;
            for(let i in _this.questionInfo.wj_question){
              _this.questionInfo.wj_question[i].item = _this.questionInfo.wj_question[i].item != '' ? JSON.parse(_this.questionInfo.wj_question[i].item) : '';
              _this.questionInfo.wj_question[i].relation = _this.questionInfo.wj_question[i].relation != '' ? JSON.parse(_this.questionInfo.wj_question[i].relation) : '';
              for(let j in _this.questionInfo.wj_question[i].wj_item){
                _this.questionInfo.wj_question[i].wj_item[j].code = (j*1 + 1); 
              }
            }
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
    },
    delquestion(id){
      let _this = this;
        axios.delete(config.question,{data:{id:id}})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit('CHA');
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
    },
    addbinduser(){
      let _this = this;
      _this.addbindusershow = true;
      _this.binduserdetail = {};
    },
    addbind(){
      let _this = this;
      _this.addbindshow = true;
      _this.binddetail = {};
      if(_this.ordertypelist){
        axios.get(config.order_type,{params:{}})
          .then(response => {
            if(response.data.code == 200){
              _this.ordertypelist = response.data.data;
            }else{
                _this.$message.error(response.data.message);
              }
          })
          .catch(error => {
            _this.$message.error(error);
          })
      }
    },
    editbinduserdetail(id){
      let _this = this;
        axios.get(config.bind_user_detail,{params:{id:id}})
        .then(response => {
          if(response.data.code == 200){
            _this.editbinduserdetailshow = true;
            _this.binduserdetail = response.data.data;
            _this.binduserdetail.s_time = matDate(response.data.data.s_time*1000,1);
            _this.binduserdetail.e_time = matDate(response.data.data.e_time*1000,1);
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
    },
    editbind(id){
      let _this = this;
      if(_this.ordertypelist){
        axios.get(config.order_type,{params:{}})
        .then(response => {
          if(response.data.code == 200){
            _this.ordertypelist = response.data.data;
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
      }
        axios.get(config.bind_detail,{params:{id:id}})
        .then(response => {
          if(response.data.code == 200){
            _this.editbindshow = true;
            _this.binddetail = response.data.data;
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
    },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        axios.get(config.question,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      geiallquestion(page){
        let _this = this;
        axios.get(config.question,{params:{search_data:'',page:page}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.allquestdata = _this.allquestdata.concat(response.data.data.data);
              if(page < response.data.data.last_page){
                _this.geiallquestion(page+1);
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },setpage2(page){
        this.total2 = page.total;
        this.per_page2 = page.per_page;
        this.current_page2 = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      handleCurrentChange2(val) {
        this.getblist.page = val;
        this.getblists();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.code)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.question,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        
        axios.get(config.selectaction_log_type,{params:{pid:0}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.actionlogtypelist = response.data.data;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        _this.geiallquestion(1);
    }
  }, 
  created(){
      this.init();
  }
}
</script>
<style scoped>
.foot{
  margin-top: 40px;
}
.btnblue{
  margin: auto;
  display: block;
}
.meibi{
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #888888;
}
.left{
    margin-bottom: 20px;
    text-align: left;
}
.btngr{
    background-color: darkgreen;
    color: #fff;
}
.btnblack{
    background-color: #000;
    color: #fff;
  }
.gl{
  margin: auto;
    display: block;
}
.list{
  display: flex;
  min-height: 50px;
  line-height: 50px;
}
.list2{
  min-height: 50px;
  line-height: 50px;
}
.listname{
  width: 120px;
  text-align: right;
  margin-right: 30px;
}
.content{
  width: 500px;
}
.content2{
  display: flex;
}
.Relatedtopics{
  font-size: 16px;
}
.inputwidth{
  width:700px;
}
.putbindcalls > div{
  margin-bottom: 10px;
}
.conentbox{
  max-height: 75vh;
  overflow:auto;
}
.contflex{
  position: absolute;
  box-shadow: 5px 5px 5px #888888;
  width: 90%;
  background-color: white;
  z-index: 999;
  top: 75px;
}
</style>